import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Routes';

import SignIn from '../pages/SingIn';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import LogoutController from '../controller/LogoutController';
// import SecureRouter from '../pages/Router/SecureRouter';
// import WrapperComponent from 'components/routes/WrapperComponent';

// import LogoutController from 'controllers/LogoutController';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/logout" component={LogoutController} isPrivate />

      <Route component={NotFound} />
    </Switch>
  );
}
