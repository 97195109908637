import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || 'https://api-gruposinal.wisewall.com.br',
  headers: {
    'Content-Type': 'application/json',
    Origin: 'https://gruposinal.wisewall.com.br',

    'Access-Control-Allow-Origin': 'https://gruposinal.wisewall.com.br',
  },
});

export default api;
