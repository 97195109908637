import React from 'react';
import { Switch, Route as RouteDOM } from 'react-router-dom';

import Route from '../../routes/Routes';
import LeftMenu from './components/LeftMenu';
import Footer from './components/Footer';

import Home from '../../views/Home';
import NotFound from '../NotFound';
import Categorias from '../../views/Categorias';
import TableUrl from '../../views/Categorias/TableUrl';
import Search from '../../views/Search';

export default function Dashboard() {
  return (
    <div className="af-view">
      <LeftMenu />
      <Switch>
        <Route exact path="/dashboard" component={Home} isPrivate />
        <Route
          exact
          path="/dashboard/categorias"
          component={Categorias}
          isPrivate
        />
        <Route
          exact
          path="/dashboard/categorias/:category"
          component={TableUrl}
          isPrivate
        />
        <Route exact path="/dashboard/pesquisa" component={Search} isPrivate />
        <RouteDOM component={NotFound} />
      </Switch>
      <Footer />
    </div>
  );
}
