import React from 'react';
import './styles.css';

function Footer() {
  return (
    <div className="af-class-custom-footer">
      <div className="af-class-c-footer">
        <div className="w-container">
          <div className="af-class-l-footer__block">
            <div className="af-class-l-copyright__block">
              <div className="af-class-c-footer__text">
                Wisewall - Copyright BitGrow 2021 © Todos os direitos
                reservados.
              </div>
            </div>
            <div className="af-class-l-author__block">
              <div className="af-class-c-footer__text">Desenvolvimento</div>
              <a
                href="https://www.bitgrow.com.br"
                rel="noreferrer"
                target="_blank"
                className="af-class-c-footer__logo af-class-is--bitgrow w-inline-block"
              >
                <img
                  src="/images/logo_BitGrow.svg"
                  alt="logo"
                  width={33}
                  height={33}
                />
              </a>
              <div className="af-class-c-footer__text af-class-is-authors">
                v0.1.1
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
