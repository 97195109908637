/* eslint-disable react/prop-types */
import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../services/api';
import config from '../config/local.config';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem(config.key);
    const user = localStorage.getItem(`${config.key}-user`);

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });
  const [isAlteration, setIsAlteration] = useState(() => {
    const alteration = localStorage.getItem(
      `${config.key}-${data.user?.user || 'user'}-alteration`,
    );

    if (alteration) {
      return { alteration };
    }

    return false;
  });

  const signIn = useCallback(async (user, password) => {
    const response = await api.post('session', {
      user,
      password,
    });
    localStorage.setItem(config.key, response.data.token);
    localStorage.setItem(
      `${config.key}-user`,
      JSON.stringify(response.data.user),
    );

    setData({ token: response.data.token, user: response.data.user });
  }, []);
  const signOut = useCallback(() => {
    localStorage.removeItem(config.key);
    localStorage.removeItem(`${config.key}-user`);

    setData({});
  }, []);
  const removeToken = useCallback(() => {
    localStorage.removeItem(config.key);
    localStorage.removeItem(`${config.key}-user`);
  }, []);

  // Todo: alteration in data
  const changedData = useCallback(
    response => {
      localStorage.setItem(
        `${config.key}-${data.user.user}-alteration`,
        response,
      );
      setIsAlteration(response);
    },
    [data],
  );
  return (
    <AuthContext.Provider
      value={{
        isAlteration,
        changedData,
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        removeToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
