import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { useField } from '@unform/core';

function Input({ name, className, ...rest }) {
  const inputRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const { fieldName, defaultValue, error, registerField } = useField(name);
  // const [isFocused, setIsFocused] = useState(false);
  // const [isFilled, setIsFilled] = useState(false);

  // const handleInputFocus = useCallback(() => {
  //   setIsFocused(true);
  // }, []);

  // const handleInputBlur = useCallback(() => {
  //   setIsFocused(false);

  //   setIsFilled(!!inputRef.current?.value);
  // }, []);

  /* Unform -  Quando o form da um submit, ele busca pelo nome,
  pega a referência do arquivo e path onde deve pegar o dado no caso value
  */
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <input
      defaultValue={defaultValue}
      ref={inputRef}
      {...{
        ...rest,
        className: `af-class-c-form__field w-input ${className || ''}`,
      }}
    />
  );
}
Input.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
export default Input;
