import { setLocale } from 'yup';

export default () => {
  /* eslint-disable no-template-curly-in-string */
  setLocale({
    mixed: {
      default: 'Valor inválido',
      required: 'Campo obrigatório',
      oneOf: 'Deve ser um dos seguintes valores: ${values}',
      notOneOf: 'Campo não deve ser um dos seguintes valores: ${values}',
    },
    string: {
      length: 'Deve ter exatamente ${length} caracteres',
      min: 'Deve ter pelo menos ${min} caracteres',
      max: 'Deve ter no máximo ${max} caracteres',
      matches: 'Deve corresponder ao seguinte: "${regex}"',
      email: 'Deve ser um email válido',
      url: 'Deve ser um URL válido',
      trim: 'Deve ser uma string sem espaços brancos extras',
      lowercase: 'Deve conter letras minúsculas',
      uppercase: 'Deve conter letras maiúsculas',
    },
    number: {
      min: 'Deve ser maior ou igual a ${min}',
      max: 'Deve ser menor ou igual a ${max}',
      lessThan: 'Deve ser menor que ${less}',
      moreThan: 'Deve ser maior que ${more}',
      notEqual: 'Não pode ser igual a ${notEqual}',
      positive: 'Deve ser um número positivo',
      negative: 'Deve ser um número negativo',
      integer: 'Deve ser um número inteiro',
    },
    date: {
      min: 'Campo deve ser posterior a ${min}',
      max: 'Campo deve ser anterior a ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        'Campo não pode ter chaves não especificadas no formato do objeto',
    },
    array: {
      min: 'Deve ter pelo menos ${min} itens',
      max: 'Deve ter menos que ou igual a ${max} itens',
    },
  });
  /* eslint-disable no-template-curly-in-string */
};
