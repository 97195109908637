import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

export default function LogoutController() {
  const { signOut } = useAuth();
  signOut();

  return <Redirect to="/" />;
}
