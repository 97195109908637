/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';

import { message } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import Input from '../Input';

import './styles.css';
import api from '../../services/api';

Modal.setAppElement('#root');
const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    width: '30%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function ModalDom({
  open,
  onClose,
  category,
  auth,
  url,
  isAlteration,
}) {
  const [quantityInput, setQuantity] = useState([{ Nome: '' }]);
  const [loading, setLoading] = useState(false);
  const [currentInputValue, setCurrentInputValue] = useState('');

  const popSuccess = () => {
    message.success('Alterações aplicadas com sucesso', 3);
  };

  const popError = (msg = '') => {
    message.error(msg || 'Alterações não foram salvas, tente novamente!', 3);
  };

  const handleClickClose = () => {
    setQuantity([{ Nome: '' }]);
    setCurrentInputValue('');
    onClose();
  };
  const handleAddInput = () => {
    quantityInput.push({ Nome: '' });
    setQuantity(quantityInput);
  };

  const handleChangeInput = event => {
    setCurrentInputValue(event.target.value);
  };

  const handleDomainSubmit = useCallback(
    async event => {
      try {
        const domain = event.url.replaceAll(' ', '');
        const urls = [];
        urls.push(domain);
        if (isEmpty(event.category) || isEmpty(domain)) {
          popError('Preencha todos os campos!');
          return;
        }
        setLoading(true);

        await api.post(
          '/domain/',
          { category: event.category, url: urls },
          { headers: { Authorization: `Bearer ${auth}` } },
        );

        popSuccess();
        setLoading(false);
        isAlteration(true);
      } catch (err) {
        if (err.response.status === 400) {
          popError(err.response.data.err);
          return;
        }
        popError();
      } finally {
        setLoading(false);
      }
    },
    [auth, isAlteration],
  );

  const handleDomainUpdateSubmit = useCallback(
    async event => {
      try {
        const domain = event.url.replaceAll(' ', '');
        if (isEmpty(event.category) || isEmpty(domain)) {
          popError('Preencha todos os campos!');

          return;
        }

        setLoading(true);

        await api.put(
          '/domain/',
          { category: event.category, url, update_url: domain },
          { headers: { Authorization: `Bearer ${auth}` } },
        );

        popSuccess();
        isAlteration(true);
      } catch (err) {
        if (err.response.status === 400) {
          popError(err.response.data.err);
          return;
        }
        popError();
      } finally {
        setLoading(false);
      }
    },
    [auth, url, isAlteration],
  );

  return (
    <Modal isOpen={open} style={customStyles} contentLabel="Adicionar Elemento">
      <div className="af-modal-content">
        <div className="af-modal-header">
          <h2>{!url ? 'Adicionar URLs' : 'Atualizar URL'}</h2>
          <button type="button" onClick={handleClickClose}>
            <CloseOutlined />
          </button>
        </div>
        <Form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          onSubmit={!url ? handleDomainSubmit : handleDomainUpdateSubmit}
          method="post"
          className="af-class-c-login__form-block"
        >
          <Input
            type="text"
            maxLength={256}
            name="category"
            data-name="category 2"
            placeholder="category"
            id="category"
            required
            className="af-class-c-form__field w-input"
            value={category}
            disabled
          />
          <div className="af-modal-content-inputs">
            <Input
              type="text"
              maxLength={256}
              name="url"
              data-name="Url 2"
              placeholder="example.com"
              id="url"
              required
              onChange={handleChangeInput}
              value={currentInputValue || url}
              className="af-class-c-form__field w-input"
            />
            {/* {quantityInput.map(ipt => {
              return (
                <div key={ipt.length}>
                  <Input
                    type="text"
                    maxLength={256}
                    name="url"
                    data-name="Url 2"
                    placeholder="Url"
                    id="url"
                    required
                    onChange={handleChangeInput}
                    value={currentInputValue || url}
                    className="af-class-c-form__field w-input"
                  />
                </div >

              );
            })} */}
            {!url && (
              <button
                style={{ display: 'none' }}
                type="button"
                onClick={handleAddInput}
              >
                <PlusOutlined />
              </button>
            )}
          </div>

          <button type="submit" className="af-class-c-button w-button">
            {!loading ? 'Enviar' : 'Enviando...'}
          </button>
        </Form>
      </div>
    </Modal>
  );
}
