/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Table, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Header from '../../components/Header';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import capitalize from '../../utils/CapitalizeString';
import SearchBarTop from '../../components/SerachBarTop';
import ModalDom from '../../components/Modal';
import PopConfirm from '../../components/PopConfirm';

const popInfo = () => {
  message.info('Preencha o campo de pesquisa!', 3);
};
const popSucess = () => {
  message.success('Operação realizada com sucesso!', 3);
};

export default function Search() {
  const [globalDenys, setGlobalDenys] = useState([]);
  const [currentUrl, setCurrentUrl] = useState('');
  const [modalIsOpen, setIsModalIsOpen] = useState(false);
  const [popConfirm, setPopConfirm] = useState(false);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);

  const { token, changedData } = useAuth();
  const history = useHistory();
  let i = 0;

  const filterByText = useCallback(
    async value => {
      setLoading(true);
      setGlobalDenys([]);
      if (value === '') {
        popInfo();
        return;
      }

      try {
        const response = await api.get(`/domain/search/${value}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGlobalDenys(response.data);
        setLoading(false);
      } catch (err) {
        if (err?.response.status === 401) {
          history.push('/logout');
        }
        console.log(err);
      }
    },
    [history, token],
  );

  const handleOpenModalAction = useCallback((grade, url) => {
    setCurrentUrl(url);
    setCategory(grade);
    setIsModalIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalIsOpen(false);
    setPopConfirm(false);
  }, []);

  const handleClickDelete = useCallback(async () => {
    const domainDeletedOfList = [];

    await api.post(
      '/domain/delete',
      { category, url: currentUrl },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    globalDenys.forEach(list => {
      const deletedDomain = list.domains.filter(domain => {
        return domain !== currentUrl;
      });
      if (deletedDomain.length === 0) {
        return;
      }
      const newList = {
        category: list.category,
        domains: deletedDomain,
      };
      domainDeletedOfList.push(newList);
    });
    setGlobalDenys(domainDeletedOfList);
    changedData(true);
    setCurrentUrl('');
    popSucess();
  }, [category, changedData, token, currentUrl, globalDenys]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Dominíos',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Categorias',
      dataIndex: 'category',
      key: 'category',
    },

    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => {
        const categorys = record.category.substring(
          record.category.indexOf('Wise') + `Wise\r`.length,
        );
        return (
          <Space size="middle">
            {record.category.indexOf('Wise') !== -1 && (
              <div>
                <button
                  type="button"
                  onClick={() =>
                    handleOpenModalAction(categorys, record.domain)
                  }
                >
                  <EditOutlined
                    style={{
                      fontSize: '22px',
                      color: '#194C80',
                      margin: '0 5px',
                      backgroundColor: 'transparent',
                    }}
                  />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentUrl(record.domain);
                    setCategory(categorys);
                    setPopConfirm(true);
                  }}
                >
                  <DeleteOutlined
                    style={{
                      fontSize: '22px',
                      color: '#194C80',
                      margin: '0 5px',
                    }}
                  />
                </button>
              </div>
            )}
          </Space>
        );
      },
    },
  ];

  const dataTable = [];

  globalDenys
    ? globalDenys.forEach(listDeny => {
        listDeny.domains.forEach(domain => {
          i += 1;

          dataTable.push({
            key: i,
            id: i,
            domain,
            category: capitalize(listDeny.category),
          });
        });
      })
    : [
        {
          key: i,
          id: i,
          category: 'Loading...',
          quantity: 'Loading...',
        },
      ];

  return (
    <div className="af-view">
      <Header titulo="Pesquisar Dominíos" />
      <div className="af-class-l_content">
        <SearchBarTop search={filterByText} />

        <div className="w-container">
          <Table
            dataSource={dataTable}
            columns={columns}
            pagination={{ pageSize: 30 }}
            loading={loading}
          />
        </div>
      </div>
      <ModalDom
        open={modalIsOpen}
        onClose={handleCloseModal}
        category={category}
        url={currentUrl}
        auth={token}
        isAlteration={changedData}
      />
      <PopConfirm
        open={popConfirm}
        onClose={handleCloseModal}
        category={category}
        action={handleClickDelete}
      />
    </div>
  );
}
