import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Button } from 'antd';

import { useAuth } from '../../hooks/auth';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to="/logout" className="">
        Sair
      </Link>
    </Menu.Item>
  </Menu>
);

function Header({ titulo }) {
  const { user } = useAuth();

  return (
    <div className="af-class-l_topbar">
      <Button type="link" className="af-class-c-brand__block w-nav-brand">
        <h2 className="af-class-textwhite">Wisewall</h2>
      </Button>
      <div className="af-class-l-topbar__block">
        <h2 className="af-class-c-page__name">{titulo}</h2>
        <div className="af-class-c-topbar__time-hour">30/11/2018 — 09:58</div>
      </div>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        overlayClassName="af-class-c-userprofile"
        overlayStyle={{ marginTop: '30px !important' }}
      >
        <div className="af-class-c-userprofile__block w-dropdown">
          <div className="af-class-c-userprofile__toogle w-dropdown-toggle">
            <img
              src="/images/usuario.png"
              alt="Usuário"
              className="af-class-c-userprofile__avatar"
            />
            <div className="af-class-c-userprofile__name">{user.name}</div>
            <div
              className="af-class-c-userprofile__toogle-arrow dropdown"
              id="userNav"
            >
              <span />
              <i className="fa fa-angle-down" />
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
}
Header.propTypes = {
  titulo: PropTypes.string,
};
Header.defaultProps = {
  titulo: 'Dashboard',
};
export default Header;
