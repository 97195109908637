/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Space, Table, Divider, Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import ModalDom from '../../components/Modal';
import PopConfirm from '../../components/PopConfirm';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import capitalize from '../../utils/CapitalizeString';

function TableUrl({ location, match }) {
  const [selectedBox, setSelectedBox] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [domain, setDomain] = useState(location.state.domain[0]);
  const [modalIsOpen, setIsModalIsOpen] = useState(false);
  const [popConfirm, setPopConfirm] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [dataTable, setDataTable] = useState([
    {
      key: 0,
      id: 'Loading...',
      url: 'Loading...',
    },
  ]);

  const { token, changedData } = useAuth();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            TableUrl.searchInput = node;
          }}
          placeholder={`Pesquise ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => TableUrl.searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const { category } = match.params;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedBox(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: false ? record.url === '' : true,
      name: record.url,
    }),
  };

  const handleOpenModalAction = useCallback(url => {
    setCurrentUrl(url);
    setIsModalIsOpen(true);
  }, []);
  const handleOpenPopConfirm = useCallback(url => {
    setCurrentUrl(url);
    setPopConfirm(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalIsOpen(false);
    setPopConfirm(false);
  }, []);

  const handleClickDelete = useCallback(async () => {
    await api.post(
      '/domain/delete',
      { category, url: currentUrl },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    const updatedDomains = domain.url.filter(
      domainUrl => currentUrl !== domainUrl,
    );
    setDomain({ ...domain, url: updatedDomains });
    changedData(true);
    setCurrentUrl('');
  }, [category, token, domain, currentUrl, changedData]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      ...getColumnSearchProps('url'),
    },

    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="middle">
            <button
              type="button"
              onClick={() => handleOpenModalAction(record.url)}
            >
              <EditOutlined
                style={{
                  fontSize: '22px',
                  color: '#194C80',
                  margin: '0 5px',
                }}
              />
            </button>
            <button
              type="button"
              onClick={() => handleOpenPopConfirm(record.url)}
            >
              <DeleteOutlined
                style={{
                  fontSize: '22px',
                  color: '#194C80',
                  margin: '0 5px',
                }}
              />
            </button>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    let i = 0;
    const contentDataTable = domain.url.map(URL => {
      i += 1;

      return {
        key: i,
        id: i,
        url: URL,
      };
    });
    setDataTable(contentDataTable);
  }, [domain]);

  // TODO: Ainda não está sendo utilizado!
  const handleClickDeleteSelected = useCallback(async () => {
    // await api.post('/domain/delete', { category, url }, { headers: { Authorization: `Bearer ${token}` } })
    // const updatedDomains = domain.url.filter(domainUrl => url !== domainUrl)
    // setDomain({ ...domain, url: updatedDomains })
  }, []);
  return (
    <div className="af-view">
      <Header titulo={`Categorias - ${capitalize(category)}`} />
      <div className="af-class-l_content" style={{ marginTop: '15px' }}>
        <div className="af-wizewall-content-category">
          <Link
            to="/dashboard/categorias"
            className="af-class-c-button w-button"
            type="button"
          >
            Voltar
          </Link>
          <div>
            <button
              className="af-class-c-button w-button"
              type="button"
              onClick={() => handleOpenModalAction('')}
            >
              Adicionar
            </button>
          </div>
        </div>

        <div className="w-container">
          <div className="table-content">
            <Divider />
            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              dataSource={dataTable}
              columns={columns}
              pagination={{ pageSize: 30 }}
            />
          </div>

          <button
            className="af-class-c-button w-button"
            style={
              selectedBox.length === 0
                ? {
                    display: 'none',
                    backgroundColor: '#B3B3FC',
                    marginBottom: '5px',
                  }
                : {
                    display: 'none',
                    backgroundColor: '#5f5ff6',
                    marginBottom: '5px',
                  }
            }
            disabled={selectedBox.length === 0}
            type="button"
            onClick={handleClickDeleteSelected}
          >
            Remover Selecionados
          </button>
          <ModalDom
            open={modalIsOpen}
            onClose={handleCloseModal}
            category={category}
            url={currentUrl}
            auth={token}
            isAlteration={changedData}
          />
          <PopConfirm
            open={popConfirm}
            onClose={handleCloseModal}
            category={category}
            action={handleClickDelete}
          />
        </div>
      </div>
    </div>
  );
}

TableUrl.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      domain: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string.isRequired,
          url: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(TableUrl);
