import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Space, Table, message } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';

import Header from '../../components/Header';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import capitalize from '../../utils/CapitalizeString';

export default function Categorias() {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);

  const { token, isAlteration, changedData } = useAuth();
  const history = useHistory();
  let i = 0;

  const popSuccess = () => {
    message.success('Alterações aplicadas com sucesso', 4);
  };

  const popError = () => {
    message.error('Alterações não foram salvas, contate o suporte!');
  };

  useEffect(() => {
    async function fetchApiDomains() {
      try {
        const response = await api.get('/domain', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setDomains(response.data);
      } catch (err) {
        // pegar o err.response.data e verificar qual o erro.
        // possibilidade que o back tenha encerrado! 500
        history.push('/logout');
      }
    }
    fetchApiDomains();
  }, [token, history]);

  const handleClickAplly = useCallback(async () => {
    setLoading(true);
    try {
      await api.get(`/domain/generator`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      changedData(false);
      popSuccess();
    } catch (error) {
      popError();
    } finally {
      setLoading(false);
    }
  }, [token, changedData]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Categorias',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },

    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => {
        const domain = domains
          ? domains.filter(dt => dt.category === record.category.toLowerCase())
          : [];
        return (
          <Space size="middle">
            <Link
              to={{
                pathname: `/dashboard/categorias/${record.category.toLowerCase()}`,
                state: { domain },
              }}
            >
              <EditOutlined
                style={{
                  fontSize: '22px',
                  color: '#194C80',
                }}
              />
            </Link>
          </Space>
        );
      },
    },
  ];

  const dataTable = domains
    ? domains.map(domain => {
        i += 1;

        return {
          key: i,
          id: i,
          quantity: domain.url.length,
          category: capitalize(domain.category),
        };
      })
    : [
        {
          key: i,
          id: i,
          category: 'Loading...',
          quantity: 'Loading...',
        },
      ];

  return (
    <div className="af-view">
      <Header titulo="Categorias" />
      <div className="af-class-l_content" style={{ marginTop: '15px' }}>
        <div className="af-wizewall-content">
          <button
            onClick={handleClickAplly}
            className="af-class-c-button w-button"
            style={
              isAlteration
                ? { backgroundColor: '#5F5FF6' }
                : { backgroundColor: '#B3B3FC' }
            }
            type="button"
            disabled={!isAlteration}
          >
            {!loading ? 'Aplicar Alterações' : <LoadingOutlined />}
          </button>
        </div>

        <div className="w-container">
          <Table
            dataSource={dataTable}
            columns={columns}
            pagination={{ pageSize: 30 }}
          />
        </div>
      </div>
    </div>
  );
}
