/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import './styles.css';
import api from '../../services/api';

Modal.setAppElement('#root');
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function PopConfirm({ open, onClose, category, action }) {
  const handleClickClose = () => {
    onClose();
  };

  const handleClickRemove = () => {
    action();
    onClose();
  };

  return (
    <Modal isOpen={open} style={customStyles} contentLabel="Adicionar Elemento">
      <div className="af-modal-content">
        <p>Deseja realmente remover esta entrada?</p>
        <button
          type="button"
          onClick={handleClickClose}
          className="af-class-c-button w-button"
        >
          Cancelar
        </button>
        <button
          type="button"
          onClick={handleClickRemove}
          className="af-class-c-button__secondary w-button"
        >
          Remover
        </button>
      </div>
    </Modal>
  );
}
