import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

function SearchBarTop({ search }) {
  const [inputValue, setInputValue] = useState('');
  const handleChangeInput = useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  const handleSearch = useCallback(
    e => {
      if (e) {
        e.preventDefault();
      }
      search(inputValue);
    },
    [search, inputValue],
  );

  return (
    <div className="af-class-c-search__block">
      <div className="af-class-c-search__form-block w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="af-class-c-search__form"
          onSubmit={handleSearch}
        >
          <div className="af-class-c-search__input-block">
            <div className="af-class-c-search__icon">
              <img src="/images/ic.search.svg" alt="search" />
            </div>
            <input
              type="text"
              maxLength={256}
              name="Busca"
              data-name="Busca"
              id="Busca"
              className="af-class-c-search__text-field w-input"
              onChange={handleChangeInput}
            />
          </div>
          <input
            type="button"
            value="Buscar"
            data-wait="Aguardar..."
            className="af-class-c-button__secondary w-button"
            onClick={handleSearch}
          />
        </form>
      </div>
    </div>
  );
}

SearchBarTop.propTypes = {
  search: PropTypes.func.isRequired,
};

export default SearchBarTop;
