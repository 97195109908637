import React from 'react';

import Header from '../../components/Header';

export default function Home() {
  return (
    <div className="af-view">
      <Header titulo="Dashboard" />

      <div className="af-class-l_content">
        <div className="w-container">
          <div className="af-class-c-dashboard-card">
            <div className="af-class-l-title-buttons__wrapper">
              <h2>Seja bem-vindo ao gestor de acessos!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
