import React from 'react';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  WifiOutlined,
  ToolOutlined,
  LogoutOutlined,
  SearchOutlined,
} from '@ant-design/icons';
// import { useAuth } from '../../hooks/auth';

function LeftmenuView() {
  // const { signOut } = useAuth();
  return (
    <div className="af-view">
      <div className="af-class-l_menu">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration={400}
          className="af-class-c-navbar w-nav"
        >
          <div className="af-class-c-navmenu__title">Menu</div>
          <nav
            role="navigation"
            className="af-class-l-navmenu w-nav-menu dropdown"
          >
            <Link
              to="/dahboard"
              className="af-class-c-navmenu__item w-nav-link"
            >
              <HomeOutlined /> Home
            </Link>

            <Link
              to="/dashboard/categorias"
              className="af-class-c-navmenu__item w-nav-link"
            >
              <WifiOutlined />
              Categorias
            </Link>
            <Link
              to="/dashboard/pesquisa"
              className="af-class-c-navmenu__item w-nav-link"
            >
              <SearchOutlined /> Pesquisar
            </Link>
            <Link
              to="/dashboard"
              className="af-class-c-navmenu__item w-nav-link"
            >
              <ToolOutlined /> Configurações
            </Link>
            <Link
              to={{
                pathname: '/logout',
              }}
              className="af-class-c-navmenu__item w-nav-link"
            >
              <LogoutOutlined /> Sair
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default LeftmenuView;
