import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <span>
      <span id="not-found-page" className="af-view">
        <div>
          <div className="af-class-utility-page-wrap">
            <div>
              <img src="/images/404.png" alt="Erro 404" />
              <h1>Página não encontrada :/</h1>
              <p>
                A página que você tentou acessar não está disponível ou não
                existe.
              </p>
              <Link
                to="/"
                className="af-class-c-button af-class-c-button__add w-button"
              >
                Ir para Home
              </Link>
            </div>
          </div>
          {/* [if lte IE 9]><![endif] */}
        </div>
      </span>
    </span>
  );
}
