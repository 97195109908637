import React from 'react';
import ReactDOM from 'react-dom';

// import * as serviceWorker from './serviceWorker';
import configLocale from './configLocale';
import 'antd/dist/antd.css';
import App from './App';
import './index.css';

configLocale();

ReactDOM.render(<App />, document.getElementById('root'));
