import React, { useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { useAuth } from '../../hooks/auth';
import Input from '../../components/Input';

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const { signIn } = useAuth();

  const history = useHistory();

  const handleLoginSubmit = useCallback(
    async event => {
      try {
        if (isEmpty(event.login) || isEmpty(event.password)) {
          setUsernameErrorMessage('Favor digite o campo de login!');
          setPasswordErrorMessage('Favor digite o campo da senha!');
          return;
        }

        setLoading(true);

        await signIn(event.login, event.password);
        history.push('/dashboard');
      } catch (err) {
        setErrorMessage('Dados de autenticação incorretos!');
      } finally {
        setLoading(false);
      }
    },
    [signIn, history],
  );

  return (
    <span>
      <span className="af-view">
        <div>
          <div className="af-class-c-wrapper__login">
            <div className="af-class-c-flexblock-full af-class-login-first">
              <div className="af-class-c-login__intro-block">
                <a href="#logo" className="af-class-c-brand w-inline-block">
                  <img
                    src="images/BG-logo2x_1BG-logo@2x.png"
                    width={50}
                    alt="logoLabgrow"
                  />
                  <h1 className="c-display-title__systemname">Wisewall</h1>
                </a>
                <h1 className="af-class-c-display-title">
                  Gerencie sua rede com facilidade, performace e segurança
                </h1>
                <p className="af-class-c-login__paragraph">
                  O Wisewall é uma solução de Firewall de última geração, que
                  permite o controle de sua rede e de seus componentes, com o
                  máximo de segurança mas sem perder a praticidade e
                  performance.
                  <br />
                  Saiba mais em nosso site{' '}
                  <a
                    href="https://www.wisewall.com.br"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.wisewall.com.br
                  </a>
                </p>
                <div className="af-class-c-login__paragraph_copyright">
                  Wisewall - Copyright BitGrow 2021 © Todos os direitos
                  reservados.
                </div>
              </div>
            </div>
            <div className="af-class-c-flexblock-full af-class-login-scnd">
              <div className="af-class-c-login__form w-form">
                <a
                  href="#casa"
                  className="af-class-c-brand__mobile w-inline-block"
                >
                  <img
                    src="images/BG-logo2x_1BG-logo@2x.png"
                    width={41}
                    alt="background"
                  />
                </a>
                <Form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  onSubmit={handleLoginSubmit}
                  method="post"
                  className="af-class-c-login__form-block"
                >
                  <Input
                    type="text"
                    maxLength={256}
                    name="login"
                    data-name="Login 2"
                    placeholder="Login"
                    id="login"
                    required
                    {...{
                      className: `af-class-c-form__field w-input`,
                    }}
                  />
                  <div className="validation-message">
                    {usernameErrorMessage}
                  </div>
                  <Input
                    type="password"
                    maxLength={256}
                    name="password"
                    data-name="Senha 2"
                    placeholder="Senha"
                    id="password"
                    required
                    {...{
                      className: `af-class-c-form__field w-input`,
                    }}
                  />
                  <div className="validation-message">
                    {passwordErrorMessage}
                  </div>
                  <button
                    type="submit"
                    value="Login"
                    id="login-button"
                    data-wait="Por favor aguarde..."
                    {...{
                      className: `af-class-c-button af-class-is--login w-button`,
                    }}
                  >
                    {!loading ? 'Entrar' : 'Por favor aguarde...'}
                  </button>

                  <div className="validation-message">{errorMessage}</div>
                  <div className="af-class-l-login__columns">
                    <div className="w-checkbox">
                      <input
                        style={{ display: 'none' }}
                        type="checkbox"
                        id="checkbox-2"
                        name="checkbox-2"
                        data-name="Checkbox 2"
                        {...{
                          className: `w-checkbox-input`,
                        }}
                      />

                      <label
                        style={{ display: 'none' }}
                        htmlFor="checkbox-2"
                        className="af-class-c-form__toogle-label w-form-label"
                      >
                        Lembrar de mim
                      </label>
                    </div>
                    <a
                      style={{ display: 'none' }}
                      href="#forgot-password"
                      {...{
                        className: `af-class-inlinelink1 af-class-is--esqueceu`,
                      }}
                    >
                      Esqueceu a senha?
                    </a>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          {/* [if lte IE 9]><![endif] */}
        </div>
      </span>
    </span>
  );
}

export default SignIn;
